<template>
  <div class="tracking-item" v-for="timeline in timelines" :key="timeline.added_date">
    <div class="icon-column quick-link">
      <div class="icon">
        <i class="bi" :class="selectIcon(timeline.status_id)"></i>
      </div>
    </div>
    <div class="tracking-date">{{ formatDate(timeline.added_date).date }}<span>{{ formatDate(timeline.added_date).time }}</span></div>
    <div class="tracking-content">
      <div class="title">{{ timeline.title }}</div>
      <div class="description">
        <div v-for="comments in timeline.comments" :key="comments.id">
         <p>{{ comments.text }}</p>
        </div>
        <div v-for="communication in timeline.comms" :key="communication.id">
          <strong v-if="communication.title && communication.title.toLowerCase() != '(no subject)'">{{ communication.title }}</strong>
          <p>{{ communication.text }}</p>
        </div>
      </div>
      <div class="action" v-if="timeline.treatment_plan">
        <a :href="`/treatment-plan?ref=${timeline.treatment_plan.third_party_reference}`" class="btn btn-secondary" target="_blank">View</a>
        <button class="btn btn-secondary sharelink" type="submit" @click="shareButtonClicked(timeline.treatment_plan.url+'&hideModify=true')"><i class="bi bi-share"></i></button>
      </div>
    </div>
  </div>

</template>
  
  <style scoped lang="scss">  
  .tracking-item {

      border-left: 1px solid #e5e5e5;
      position: relative;
      padding: 0rem 1.5rem 2rem 3.5rem;
      font-size: 1rem;
      margin-left: 3rem;
      min-height: 5rem;
      &:last-of-type {
        padding-bottom: 0rem;
        min-height:2.5rem;
      }
      .tracking-date {
        span {
          color: #888;
          font-size: 85%;
          padding-left: 0.4rem;
        }
      }

      .tracking-content {
        .description {
          display: block;
          color: #888;
          font-size: 85%;
          max-width: 600px;
          text-align: justify;
        }
      }
      .action {
        margin-top: 0.5rem;
          .btn {
            font-size:14px;
            margin-right: .5rem;

            @media screen and (max-width: 768px){
              margin-top:8px;
              width:calc(50% - .5rem);
            }
           
            &.adjustment {
              @media screen and (max-width: 500px){
                width: calc(100% - .5rem);
              }
             }
          }
        }

      .icon-column {
        line-height:2.6rem;
        position:absolute;
        left:-1.6rem;
        width:2.6rem;
        height:2.6rem;

        .icon
        {
          background: $swift-lighter-grey;
          border-radius: 10px;
          aspect-ratio: 1/1;
          font-size: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          color:#000;
        }
       
      }

      @media(min-width:992px) {
        margin-left:11rem;

        .tracking-date {
          position:absolute;
          left:-11rem;
          width:7.5rem;
          text-align:right;
          span {
            display:block
          }
        }
        .tracking-content {
          padding:0;
          background-color:transparent
        }
      }
  }
  .hide {
    display:none;
  }
  </style>
  
<script>

export default {
        emits: ['button-clicked', 'share-button-clicked'],
        data() {
          return {
            ACCEPT: 1,
            MODIFY: 3,

            STATUS_COMMUNICATION: 166,
            ALIGNER_DETAILS_RECEIVED: 255,
            TREATMENT_PLAN_RECEIVED: 257,
            TREATMENT_PLAN_REQUESTED: 256,
            TREATMENT_PLAN_MODIFICATION_REQUESTED: 258,
            TREATMENT_PLAN_APPROVED: 259,
            ALIGNER_MANUFACTURE_STARTED: 262,
            modal: null,
            selectedAction: '',
          }
        },
        props: {
          timelines: {
            type: Object,
            required: true
          },
        },
        methods: {

          buttonClicked(action, treatment_plan_id) {
            this.$refs.timelineModal.showModal(action, treatment_plan_id);
          },
            shareButtonClicked(url) {
              this.$emit('share-button-clicked', url);
            },
            formatDate(dateTimeStr) {

            let dateTimeObj = new Date(dateTimeStr);
            let day = String(dateTimeObj.getDate()).padStart(2, '0');
            let month = String(dateTimeObj.getMonth() + 1).padStart(2, '0');
            let year = dateTimeObj.getFullYear();

            let hours = String(dateTimeObj.getHours()).padStart(2, '0');
            let minutes = String(dateTimeObj.getMinutes()).padStart(2, '0');

            let am_pm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12 || 12;

            let date = `${day}/${month}/${year}`;
            let time = `${hours}:${minutes} ${am_pm}`;

            return {date, time};
          },
          selectIcon(statusId) {
            switch (statusId) {
              case this.STATUS_COMMUNICATION:
                return 'bi-chat-dots';
              case 'production':
                return 'bi-building';
              case this.TREATMENT_PLAN_RECEIVED:
                return 'bi-mailbox';
              case this.TREATMENT_PLAN_APPROVED:
                return 'bi-check-lg';
              case this.TREATMENT_PLAN_REQUESTED:
                return 'bi-envelope';
              case this.TREATMENT_PLAN_MODIFICATION_REQUESTED:
                return 'bi-pencil';
              case this.ALIGNER_DETAILS_RECEIVED:
                return 'bi-cart2';
              case this.ALIGNER_MANUFACTURE_STARTED:
                return 'bi-building';
              default:
                return 'bi-check-lg';
            }
          },
        }
    }
</script> 